import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/books_read_caleb',
    name: 'books read caleb',
    component: () => import('../views/BooksReadView.vue'),
    props: { name: "Caleb" },
  },
  {
    path: '/books_read_cj',
    name: 'books read cj',
    component: () => import('../views/BooksReadView.vue'),
    props: { name: "CJ" },

  },
  {
    path: '/books',
    name: 'books',
    component: () => import('../views/BooksView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/solar',
    name: 'solar',
    component: () => import('../views/SolarView.vue'),
  },
  {
    path: '/shopping_items',
    name: 'shopping_items',
    component: () => import('../views/ShoppingView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shopping_list',
    name: 'shopping_list',
    component: () => import('../views/ShoppingListView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/game_camera',
    name: 'game_camera',
    component: () => import('../views/GameCamera.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/financial_budget_berkstresser',
    name: 'financial_budget_berkstresser',
    component: () => import('../views/FinancialBudgetView.vue'),
    props: { name: "Berkstresser" },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/financial_summary_berkstresser',
    name: 'financial_summary_berkstresser',
    component: () => import('../views/FinancialSummaryView.vue'),
    props: { name: "Berkstresser" },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/financial_summary_ian',
    name: 'financial_summary_ian',
    component: () => import('../views/FinancialSummaryView.vue'),
    props: { name: "Ian" },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/financial_summary_mia',
    name: 'financial_summary_mia',
    component: () => import('../views/FinancialSummaryView.vue'),
    props: { name: "Mia" },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../views/TaskListView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/conditioning',
    name: 'conditioning',
    component: () => import('../views/ConditioningMeasurementView.vue'),
    props: { name: "Conditioning", amountPrecision: 0 },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/measurements',
    name: 'measurements',
    component: () => import('../views/ConditioningMeasurementView.vue'),
    props: { name: "Measurement", amountPrecision: 2 },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nutrition',
    name: 'nutrition',
    component: () => import('../views/NutritionView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nutrition_management',
    name: 'nutrition_management',
    component: () => import('../views/NutritionManagementView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fitness_history',
    name: 'fitness_history',
    component: () => import('../views/FitnessHistoryView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/blood_pressure',
    name: 'blood_pressure',
    component: () => import('../views/BloodPressureView.vue'),
    meta: {
      title: 'Blood Pressure',
      requiresAuth: true
    }
  },
  {
    path: '/weight_lifting',
    name: 'weight_lifting',
    component: () => import('../views/WeightLiftingView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/thermostat',
    name: 'thermostat',
    component: () => import('../views/ThermostatView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/speech/:activeTab?/:selectedAzureVoice?',
    name: 'speech',
    component: () => import('../views/SpeechView.vue'),
  },
  {
    path: '/universal_classroom',
    name: 'UniversalClassroom',
    component: () => import('../views/UniversalClassroomView.vue'),
  },
  {
    path: '/gnucash_berkstresser',
    name: 'gnucash_berkstresser',
    component: () => import('../views/GnucashBerkstresser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/conditioning_management',
    name: 'conditioning_management',
    component: () => import('../views/CMEManagementView.vue'),
    props: { name: "Conditioning", showUnits: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/measurement_management',
    name: 'measurement_management',
    component: () => import('../views/CMEManagementView.vue'),
    props: { name: "Measurement", showUnits: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/weight_lifting_management',
    name: 'weight_lifting_management',
    component: () => import('../views/CMEManagementView.vue'),
    props: { name: "Exercise", showUnits: false },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/garmin',
    name: 'garmin',
    component: () => import('../views/GarminSignOnView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/garmin_activities',
    name: 'garmin_activities',
    component: () => import('../views/GarminActivitiesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('../views/NotFoundPageView.vue'),
  },
  { path: '/callback', component: LoginCallback }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(navigationGuard);

export default router;
