<template>
  <div class="card relative z-2">
    <Menubar :model="((
      authState?.isAuthenticated ? items
        .concat((authState?.accessToken.claims.groups as [string]).includes('CommonAccess') ? commonItems : [])
        .concat(fitnessItems)
        .concat((authState?.accessToken.claims.groups as [string]).includes('FitnessAdmin') ? fitnessAdminItems as [] : [])
        .concat(shoppingItems)
        .concat((authState?.accessToken.claims.groups as [string]).includes('GnuCashBerkstresser') ? gnucashBerkstresserItems as [] : [])
        .concat(privacyItems as []) : items.concat(privacyItems as [])) as MenuItem[])">
      <template #start>
        <a href="/" style="margin-right:1em;"><img alt="logo" src="favicon.svg" height="40" class="mr-2" /></a>
      </template>
      <template #end>
        <button v-if="authState?.isAuthenticated" v-on:click="logout" id="logout" class="pi pi-sign-out"
          :title="`Logout ${authState.accessToken?.claims.sub}`" style="height:2.5em;cursor:pointer">&nbsp;Logout</button>
        <button class="pi pi-sign-in" v-else v-on:click="login" id="login"
          style="height:2.5em;cursor:pointer;">&nbsp;Login</button>
      </template>
      <template #item="{ label, item, props, root }">
        <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span :class="[root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']" v-bind="props.submenuicon" />
        </a>
      </template>
    </Menubar>
  </div>
  <router-view />
</template>

<script lang="ts">
export default {
  name: 'app',
  methods: {
    login() {
      this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout() {
      await this.$auth.signOut()
    }
  },
  data() {
    return {
      personId: null,
      gnucashBerkstresserItems: [
        {
          label: 'Gnucash',
          route: '/gnucash_berkstresser'
        }
      ],
      commonItems: [
        {
          label: 'Common',
          items: [
            {
              label: 'Book Admin',
              route: '/books'
            },
            {
              separator: true
            },
            {
              label: 'Financial Budget',
              route: '/financial_budget_berkstresser'
            },
            {
              label: 'Financial Summary',
              route: '/financial_summary_berkstresser'
            },
            {
              label: 'Financial Summary - Ian',
              route: '/financial_summary_ian'
            },
            {
              label: 'Financial Summary - Mia',
              route: '/financial_summary_mia'
            },
            {
              separator: true
            },
            {
              label: 'Game Camera',
              route: '/game_camera'
            },
            {
              label: 'Tasks',
              route: '/tasks'
            },
            {
              label: 'Thermostat',
              route: '/thermostat'
            },
          ]
        }
      ],

      fitnessItems: [
        {
          label: 'Fitness & Nutrition',
          items: [
            {
              label: 'Blood Pressure',
              route: '/blood_pressure'
            },
            {
              label: 'Conditioning',
              route: '/conditioning'
            },
            {
              label: 'Measurements',
              route: '/measurements'
            },
            {
              label: 'Nutrition',
              route: '/nutrition'
            },
            {
              label: 'Weight Lifting',
              route: '/weight_lifting'
            },
            {
              separator: true
            },
            {
              label: 'Fitness History',
              route: '/fitness_history'
            }
          ]
        }
      ],

      fitnessAdminItems: [
        {
          label: 'Fitness Admin',
          items: [
            {
              label: 'Conditioning Management',
              route: '/conditioning_management'
            },
            {
              label: 'Measurement Management',
              route: '/measurement_management'
            },
            {
              label: 'Nutrition Management',
              route: '/nutrition_management'
            },
            {
              label: 'Weight Lifting Management',
              route: '/weight_lifting_management'
            },
            {
              separator: true
            },
            {
              label: 'Garmin - Experimental',
              route: '/garmin_activities'
            }
          ]
        }
      ],

      shoppingItems: [
        {
          label: 'Shopping',
          items: [
            {
              label: 'Global Items List',
              route: '/shopping_items'
            },
            {
              label: 'Shopping List',
              route: '/shopping_list'
            }
          ]
        }
      ],

      privacyItems: [
        {
          label: 'Privacy & Terms of Service',
          route: '/privacy'
        }
      ],

      items: [
        {
          label: 'General',
          items: [
            {
              label: 'Books Read - Caleb',
              route: '/books_read_caleb'
            },
            {
              label: 'Books Read - CJ',
              route: '/books_read_cj'
            },
            {
              label: 'Solar Shed',
              route: '/solar'
            },
            {
              separator: true,
            },
            {
              label: 'Speech Synthesis',
              route: '/speech'
            },
            {
              label: 'Universal Classroom',
              route: '/universal_classroom'
            },
          ]
        }
      ]
    }
  }
}
</script>

<script lang="ts" setup>
import Menubar from 'primevue/menubar';
import 'primeicons/primeicons.css';
import { MenuItem } from 'primevue/menuitem';
</script>
