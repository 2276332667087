<template>
  <div class="hello">
    <h1>Home Page</h1>
    <h2>Welcome</h2>
    <img src="@/assets/icon.svg" style="width:30%;display:block;margin-left:auto;margin-right:auto;border-radius:2em;">
  </div>
</template>

<script lang="ts">
export default {
    name: "HomePage",
}
</script>