import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icon.svg'


const _hoisted_1 = { class: "hello" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Home Page", -1),
    _createElementVNode("h2", null, "Welcome", -1),
    _createElementVNode("img", {
      src: _imports_0,
      style: {"width":"30%","display":"block","margin-left":"auto","margin-right":"auto","border-radius":"2em"}
    }, null, -1)
  ])))
}