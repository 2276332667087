import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card relative z-2" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href", "target"]

import Menubar from 'primevue/menubar';
import 'primeicons/primeicons.css';
import { MenuItem } from 'primevue/menuitem';

const __default__ = {
  name: 'app',
  methods: {
    login() {
      this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout() {
      await this.$auth.signOut()
    }
  },
  data() {
    return {
      personId: null,
      gnucashBerkstresserItems: [
        {
          label: 'Gnucash',
          route: '/gnucash_berkstresser'
        }
      ],
      commonItems: [
        {
          label: 'Common',
          items: [
            {
              label: 'Book Admin',
              route: '/books'
            },
            {
              separator: true
            },
            {
              label: 'Financial Budget',
              route: '/financial_budget_berkstresser'
            },
            {
              label: 'Financial Summary',
              route: '/financial_summary_berkstresser'
            },
            {
              label: 'Financial Summary - Ian',
              route: '/financial_summary_ian'
            },
            {
              label: 'Financial Summary - Mia',
              route: '/financial_summary_mia'
            },
            {
              separator: true
            },
            {
              label: 'Game Camera',
              route: '/game_camera'
            },
            {
              label: 'Tasks',
              route: '/tasks'
            },
            {
              label: 'Thermostat',
              route: '/thermostat'
            },
          ]
        }
      ],

      fitnessItems: [
        {
          label: 'Fitness & Nutrition',
          items: [
            {
              label: 'Blood Pressure',
              route: '/blood_pressure'
            },
            {
              label: 'Conditioning',
              route: '/conditioning'
            },
            {
              label: 'Measurements',
              route: '/measurements'
            },
            {
              label: 'Nutrition',
              route: '/nutrition'
            },
            {
              label: 'Weight Lifting',
              route: '/weight_lifting'
            },
            {
              separator: true
            },
            {
              label: 'Fitness History',
              route: '/fitness_history'
            }
          ]
        }
      ],

      fitnessAdminItems: [
        {
          label: 'Fitness Admin',
          items: [
            {
              label: 'Conditioning Management',
              route: '/conditioning_management'
            },
            {
              label: 'Measurement Management',
              route: '/measurement_management'
            },
            {
              label: 'Nutrition Management',
              route: '/nutrition_management'
            },
            {
              label: 'Weight Lifting Management',
              route: '/weight_lifting_management'
            },
            {
              separator: true
            },
            {
              label: 'Garmin - Experimental',
              route: '/garmin_activities'
            }
          ]
        }
      ],

      shoppingItems: [
        {
          label: 'Shopping',
          items: [
            {
              label: 'Global Items List',
              route: '/shopping_items'
            },
            {
              label: 'Shopping List',
              route: '/shopping_list'
            }
          ]
        }
      ],

      privacyItems: [
        {
          label: 'Privacy & Terms of Service',
          route: '/privacy'
        }
      ],

      items: [
        {
          label: 'General',
          items: [
            {
              label: 'Books Read - Caleb',
              route: '/books_read_caleb'
            },
            {
              label: 'Books Read - CJ',
              route: '/books_read_cj'
            },
            {
              label: 'Solar Shed',
              route: '/solar'
            },
            {
              separator: true,
            },
            {
              label: 'Speech Synthesis',
              route: '/speech'
            },
            {
              label: 'Universal Classroom',
              route: '/universal_classroom'
            },
          ]
        }
      ]
    }
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Menubar), {
        model: ((
      _ctx.authState?.isAuthenticated ? _ctx.items
        .concat((_ctx.authState?.accessToken.claims.groups as [string]).includes('CommonAccess') ? _ctx.commonItems : [])
        .concat(_ctx.fitnessItems)
        .concat((_ctx.authState?.accessToken.claims.groups as [string]).includes('FitnessAdmin') ? _ctx.fitnessAdminItems as [] : [])
        .concat(_ctx.shoppingItems)
        .concat((_ctx.authState?.accessToken.claims.groups as [string]).includes('GnuCashBerkstresser') ? _ctx.gnucashBerkstresserItems as [] : [])
        .concat(_ctx.privacyItems as []) : _ctx.items.concat(_ctx.privacyItems as [])) as MenuItem[])
      }, {
        start: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("a", {
            href: "/",
            style: {"margin-right":"1em"}
          }, [
            _createElementVNode("img", {
              alt: "logo",
              src: "favicon.svg",
              height: "40",
              class: "mr-2"
            })
          ], -1)
        ])),
        end: _withCtx(() => [
          (_ctx.authState?.isAuthenticated)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
                id: "logout",
                class: "pi pi-sign-out",
                title: `Logout ${_ctx.authState.accessToken?.claims.sub}`,
                style: {"height":"2.5em","cursor":"pointer"}
              }, " Logout", 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "pi pi-sign-in",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args))),
                id: "login",
                style: {"height":"2.5em","cursor":"pointer"}
              }, " Login"))
        ]),
        item: _withCtx(({ label, item, props, root }) => [
          (item.route)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.route,
                custom: ""
              }, {
                default: _withCtx((routerProps) => [
                  _createElementVNode("a", _mergeProps({
                    href: routerProps.href
                  }, props.action), [
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                  ], 16, _hoisted_3)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("a", _mergeProps({
                key: 1,
                href: item.url,
                target: item.target
              }, props.action), [
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17),
                _createElementVNode("span", _mergeProps({
                  class: [root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']
                }, props.submenuicon), null, 16)
              ], 16, _hoisted_4))
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})